import {AnimatePresence, motion} from "framer-motion";
import React from "react";

import InlineRadio from "@toolbox/button-like/InlineRadio";
import {animation, onClosed, onOpen} from "../design/models";

interface IRadioSearchResultsProps<TValue> {
    selectedId?: string;
    options: TValue[];
    emptyText?: JSX.Element;
    fallbackOptions?: Partial<TValue>[];
    height?: number;

    onChange(value?: TValue): void;
    getName(value: TValue): JSX.Element | string;
    getId(value: TValue): string;
    isDisabled?(value: TValue): boolean;
    render2ndCol?(value: TValue): JSX.Element;
}

class RadioSearchResults<TValue> extends React.PureComponent<
    IRadioSearchResultsProps<TValue>
> {
    public render() {
        const {emptyText, height, onChange, render2ndCol, selectedId} =
            this.props;

        return (
            <div className="div-overflow-y" style={{height: height ?? 400}}>
                <table className="table table-sm table-striped table-borderless table-center">
                    <tbody>
                        <AnimatePresence exitBeforeEnter={true}>
                            {this.renderFallbacks()}
                            {!!emptyText && (
                                <tr>
                                    <td
                                        colSpan={
                                            render2ndCol !== undefined ? 2 : 1
                                        }
                                    >
                                        <InlineRadio
                                            value={undefined}
                                            idSuffix={`value-undefined`}
                                            isSelected={
                                                undefined === selectedId
                                            }
                                            onSelected={onChange}
                                        >
                                            {emptyText}
                                        </InlineRadio>
                                    </td>
                                </tr>
                            )}
                        </AnimatePresence>
                        {this.renderOptions()}
                    </tbody>
                </table>
            </div>
        );
    }

    private renderFallbacks() {
        const {
            fallbackOptions,
            selectedId,
            render2ndCol,
            getId,
            onChange,
            getName,
        } = this.props;
        if (!fallbackOptions || fallbackOptions.length === 0) {
            return null;
        }

        return fallbackOptions.map((fallback, i) => (
            <tr key={i}>
                <td colSpan={render2ndCol !== undefined ? 2 : 1}>
                    <InlineRadio
                        value={fallback}
                        idSuffix={`fallback-${i}`}
                        isSelected={getId(fallback as TValue) === selectedId}
                        onSelected={onChange}
                    >
                        {getName(fallback as TValue)}
                    </InlineRadio>
                </td>
            </tr>
        ));
    }

    private renderOptions() {
        const {
            options,
            selectedId,
            onChange,
            getName,
            getId,
            isDisabled,
            render2ndCol,
        } = this.props;

        return options.map((value, i) => (
            <motion.tr
                key={i}
                animate={onOpen}
                exit={onClosed}
                initial={onClosed}
                transition={animation}
            >
                <td>
                    <InlineRadio<TValue>
                        value={value}
                        idSuffix={`option-${i}`}
                        isSelected={getId(value) === selectedId}
                        disabled={isDisabled?.(value)}
                        onSelected={onChange}
                    >
                        {getName(value)}
                    </InlineRadio>
                </td>
                {render2ndCol !== undefined && <td>{render2ndCol(value)}</td>}
            </motion.tr>
        ));
    }
}

export default RadioSearchResults;
