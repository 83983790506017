import {boundMethod} from "autobind-decorator";
import React from "react";
import {Redirect} from "react-router";

import http from "@/services/http";
import {ERoles} from "@/services/models";
import session from "@/services/session";
import {ILocalizedText} from "@translate/models";

import DeleteButton from "@toolbox/button-like/DeleteButton";
import {intl2Str} from "@translate/T";

export function getTooptipSampleDelete(): ILocalizedText {
    return (intl) =>
        intl2Str(
            intl,
            "Samples might be used in analyses and will get removed from them. Then empty analyses will also get deleted.",
        );
}

interface IDeleteSampleProps {
    project: number;
    url: string;
}

interface IDeleteSampleState {
    isDeleted: boolean;
}

class DeleteSample extends React.PureComponent<
    IDeleteSampleProps,
    IDeleteSampleState
> {
    public readonly state: IDeleteSampleState = {isDeleted: false};

    public render() {
        if (!session.hasRole(ERoles.Manager, this.props.project)) {
            return null;
        }

        if (this.state.isDeleted) {
            return (
                <Redirect to={"/project/" + this.props.project} push={true} />
            );
        }

        return (
            <DeleteButton
                id="delete"
                noAnimation={true}
                titleDeleteWarning={getTooptipSampleDelete()}
                onDelete={this.delete}
            />
        );
    }

    @boundMethod
    private async delete() {
        try {
            await http.delete(this.props.url);

            this.setState({isDeleted: true});

            return true;
        } catch {
            return false;
        }
    }
}

export default DeleteSample;
