import "./RecordingIndicator.module.scss";

import {faClock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import isPast from "date-fns/isPast";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {intl2Str} from "@translate/T";

interface IRecordingIndicatorProps {
    completion: Date | undefined;
}

class RecordingIndicator extends React.PureComponent<IRecordingIndicatorProps> {
    public render() {
        const {completion} = this.props;
        if (!completion || isPast(completion)) {
            return null;
        }

        return (
            <span className="ml-1" styleName="recording-indicator">
                <IntlContext.Consumer children={this.renderIcon} />
            </span>
        );
    }

    @boundMethod
    private renderIcon(intl: IntlShape) {
        return (
            <FontAwesomeIcon
                icon={faClock}
                fixedWidth={true}
                title={intl2Str(intl, "Measurement may be in progress.")}
            />
        );
    }
}

export default RecordingIndicator;
