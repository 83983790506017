import {IRegionId} from "@/components/preferences/models";

export const HTML_PRINT_KEY = "htmlprint";
export const HTTP_UNLIMITED = "httpunlimited";
export const HTTP_LOGS_KEY = "httplogs";
export const SESSION_KEY = "session";
export const SIGNAL_KEY = "signalupdate";
export const SIGNAL_LOGS_KEY = "signallogs";
export const STORAGE_KEY = "language";
export const WARNING_KEY = "warningalerts";

export enum EExpireReasons {
    LoggedOut,
    Expired,
}

export enum ELoadingStatus {
    Nothing,
    Waiting,
    Success,
    Failed,
    Aborted,
}

// user/group roles (flags)
export enum ERoles {
    None = 0, // no role assigned
    Viewer = 1, // Can view documents
    Recorder = 2, // Can record measurements
    Editor = 4, // can edit documents and delete own documents
    Manager = 8, // can manage project and delete project documents
    Administrator = 16, // can manage users and groups
    GlobalAdministrator = 32, // the global administrator
}

export interface INavLink {
    url: string;
    text: React.ReactNode;
}

export interface ILicenses {
    cent: boolean;
    frac: boolean;
    fuge: boolean;
    reader: boolean;
    sizer: boolean;
    spoc: boolean;
    xray: boolean;
}

export interface IDate {
    id: IRegionId;
    locale?: globalThis.Locale;
}

export interface IAcsvError {
    id: number;
    doc?: IDocLink;
    path: string;
    sample?: IDocLink;
}

interface IDocLink {
    docId: string;
    name: string;
    project: number;
}
