import {faSync} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {Titled} from "react-titled";

import license from "@/services/license";
import preferences, {IUserPreferences} from "@/services/preferences";
import realtime from "@/services/realtime";
import session from "@/services/session";
import {CLICK} from "@shared/compare/chart/models";
import {IMaterial} from "../materials/models";
import {
    EDataColorSchemes,
    EDetailedFingerprint,
    EFingerprintOrientations,
    EPressureUnits,
    ESampleColorSchemes,
    ESmoothing,
    IDeviceConnection,
} from "./models";

import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import ConfirmationModal from "@toolbox/modals/ConfirmationModal";
import T, {intl2Str} from "@translate/T";
import ColorbarScheme from "./ColorbarScheme";
import ContinuousMaterial from "./ContinuousMaterial";
import DetailedFingerprint from "./DetailedFingerprint";
import DeviceConnection from "./DeviceConnection";
import DispersedMaterial from "./DispersedMaterial";
import FingerprintScheme from "./FingerprintScheme";
import GateColorScheme from "./GateColorScheme";
import Orientation from "./Orientation";
import PressureUnit from "./PressureUnit";
import Regions from "./Regions";
import SampleColorScheme from "./SampleColorScheme";
import Smoothing from "./Smoothing";

interface IPreferencesProps {
    intl: IntlShape;
}

interface IPreferencesState {
    value: IUserPreferences;
    saveError?: boolean;
    showT?: boolean;
}

class Preferences extends React.PureComponent<
    IPreferencesProps,
    IPreferencesState
> {
    public readonly state: IPreferencesState = {value: preferences.value};

    private readonly modal = React.createRef<ConfirmationModal>();

    @boundMethod
    public getTitle(parent: string) {
        return intl2Str(this.props.intl, "User Preferences | {parent}", {
            parent,
        });
    }

    @boundMethod
    public update(value: Partial<IUserPreferences>) {
        this.setState(
            {value: {...this.state.value, ...value}},
            this.saveChanges,
        );
    }

    @boundMethod
    public setColorbarScheme(colorbarScheme: EDataColorSchemes) {
        this.update({colorbarScheme});
    }

    @boundMethod
    public setDeviceConnections(deviceConnections: IDeviceConnection[]) {
        this.update({deviceConnections});
    }

    @boundMethod
    public setContinuousMaterial(fluid?: IMaterial) {
        this.update({fluid});
    }

    @boundMethod
    public setDispersedMaterial(particle?: IMaterial) {
        this.update({particle});
    }

    @boundMethod
    public setSmoothing(smoothing?: ESmoothing) {
        this.update({smoothing});
    }

    @boundMethod
    public setFingerPrintColorScheme(fingerprint: EDataColorSchemes) {
        this.update({fingerprint});
    }

    @boundMethod
    public setGateColorScheme(gateColorScheme: ESampleColorSchemes) {
        this.update({gateColorScheme});
    }

    @boundMethod
    public setFingerprintOrientation(
        fingerprintOrientation: EFingerprintOrientations,
    ) {
        this.update({fingerprintOrientation});
    }

    @boundMethod
    public setDetailedFingerprint(detailedFingerprint: EDetailedFingerprint) {
        this.update({detailedFingerprint});
    }

    @boundMethod
    public setRegion(regionId: string) {
        this.update({region: regionId});
    }

    @boundMethod
    public setSampleColorScheme(sampleColorScheme: ESampleColorSchemes) {
        this.update({sampleColorScheme});
    }

    @boundMethod
    public setUnit(unit: EPressureUnits) {
        this.update({unit});
    }

    @boundMethod
    public showReset(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (CLICK(e)) {
            e.preventDefault();

            this.setState({showT: !this.state.showT});
        }
    }

    @boundMethod
    public showModal() {
        this.modal.current?.showModal();
    }

    @boundMethod
    public async reset() {
        const value = await preferences.reset();
        this.setState({value});
    }

    public render() {
        const {value, saveError} = this.state;
        const licenses = license.getSopLicenses();

        return (
            <Titled title={this.getTitle}>
                <ContainerCol col={8}>
                    <h3>
                        <T>User preferences</T>
                    </h3>
                    <hr />

                    {!!saveError && (
                        <div className="mb-2">
                            <em className="text-danger">
                                <T>Could not save changes.</T>
                            </em>
                        </div>
                    )}

                    {licenses.frac && (
                        <PressureUnit
                            value={value.unit}
                            onChange={this.setUnit}
                        />
                    )}
                    {licenses.xray && (
                        <DeviceConnection
                            values={value.deviceConnections}
                            onChange={this.setDeviceConnections}
                        />
                    )}
                    {licenses.cent && (
                        <Orientation
                            value={value.fingerprintOrientation}
                            onChange={this.setFingerprintOrientation}
                        />
                    )}
                    {(licenses.cent || licenses.spoc) && (
                        <ContinuousMaterial
                            value={value.fluid}
                            onChange={this.setContinuousMaterial}
                        />
                    )}
                    {(licenses.cent || licenses.spoc) && (
                        <DispersedMaterial
                            value={value.particle}
                            onChange={this.setDispersedMaterial}
                        />
                    )}
                    {licenses.cent && (
                        <Smoothing
                            value={value.smoothing}
                            onChange={this.setSmoothing}
                        />
                    )}
                    <Regions value={value.region} onChange={this.setRegion} />
                    <SampleColorScheme
                        value={value.sampleColorScheme}
                        onChange={this.setSampleColorScheme}
                    />
                    {licenses.cent && licenses.spoc && (
                        <GateColorScheme
                            value={value.gateColorScheme}
                            onChange={this.setGateColorScheme}
                        />
                    )}
                    {licenses.cent && (
                        <FingerprintScheme
                            value={value.fingerprint}
                            onChange={this.setFingerPrintColorScheme}
                        />
                    )}
                    {licenses.spoc && (
                        <ColorbarScheme
                            value={value.colorbarScheme}
                            onChange={this.setColorbarScheme}
                        />
                    )}
                    {licenses.cent && (
                        <DetailedFingerprint
                            value={value.detailedFingerprint}
                            onChange={this.setDetailedFingerprint}
                        />
                    )}

                    <ConfirmationModal
                        ref={this.modal}
                        title={<T>Reset preferences</T>}
                        onSubmit={this.reset}
                    >
                        <T>This will reset all preferences to factory state.</T>
                    </ConfirmationModal>

                    <div className="form-row">
                        <div className="col" onDoubleClick={this.showReset} />
                        <div className="col-auto">{this.renderButtons()}</div>
                    </div>
                </ContainerCol>
            </Titled>
        );
    }

    private renderButtons() {
        return (
            <React.Fragment>
                {this.state.showT && (
                    <React.Fragment>
                        <button
                            type="button"
                            id="reset-local-storage"
                            data-testid="reset-local-storage"
                            className="btn btn-outline-dark mr-1"
                            onClick={session.clearLocalStorage}
                        >
                            <FontAwesomeIcon icon={faSync} className="mr-1" />
                            <React.Fragment>Reset local storage</React.Fragment>
                        </button>

                        <button
                            type="button"
                            id="reset-realtime"
                            data-testid="reset-realtime"
                            className="btn btn-outline-dark mr-1"
                            onClick={realtime.reset}
                        >
                            <FontAwesomeIcon icon={faSync} className="mr-1" />
                            <React.Fragment>Reset realtime</React.Fragment>
                        </button>
                    </React.Fragment>
                )}
                <button
                    type="button"
                    id="reset-preferences"
                    data-testid="reset-preferences"
                    className="btn btn-outline-dark"
                    onClick={this.showModal}
                >
                    <FontAwesomeIcon icon={faSync} className="mr-1" />
                    <T>Reset preferences</T>
                </button>
            </React.Fragment>
        );
    }

    @boundMethod
    private async saveChanges() {
        const valid = await preferences.setState(this.state.value);

        if (valid) {
            this.setState({saveError: false});

            return true;
        }

        this.setState({value: preferences.value, saveError: true});
        return false;
    }
}

export default injectIntl(Preferences);
