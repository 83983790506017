import React from "react";
import {IntlProvider, createIntl} from "react-intl";

import intlLocale from "@/services/intlLocale";
import preferences from "@/services/preferences";

export function getTranslator(_locale?: string, _region?: string) {
    const locale = _locale ?? getLocale(_region);
    const messages = intlLocale.messages;

    return createIntl({locale, messages});
}

function getLocale(_region?: string) {
    let locale = intlLocale.language;
    const region = _region ?? preferences.value.region;
    if (region) {
        locale += "-" + region;
    }

    return locale;
}

interface IProviderState {
    locale: string;
    messages?: Record<string, string>;
}

class Provider extends React.PureComponent<{}, IProviderState> {
    public readonly state: IProviderState = {
        locale: getLocale(),
        messages: intlLocale.messages,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        const update = () =>
            this.setState({locale: getLocale(), messages: intlLocale.messages});

        const unLocale = intlLocale.subscribe({localeChanged: update});
        const unRegion = preferences.subscribe({regionChanged: update});

        this.unsubscribe = () => {
            unRegion();
            unLocale();
        };
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {locale, messages} = this.state;

        return (
            <IntlProvider
                locale={locale}
                messages={messages}
                defaultLocale={intlLocale.language}
            >
                {this.props.children}
            </IntlProvider>
        );
    }
}

export default Provider;
