import {isEqual} from "lodash";
import React from "react";

import {EDocumentTypes} from "@/components/project/models";
import http from "@/services/http";
import {INameCheckRequest, INameCheckResponse} from "./models";

import T from "@translate/T";

export interface INameCheckProps {
    apiUrl: string;
    name: string;

    htmlNativ?: string;

    id: number;
    type: EDocumentTypes;
}

interface INameCheckState {
    isUsed?: boolean;
}

class NameChecker extends React.PureComponent<
    INameCheckProps,
    INameCheckState
> {
    public readonly state: INameCheckState = {};

    private controller?: AbortController;

    public componentDidMount() {
        this.retrieve();
    }

    public componentDidUpdate(prevProps: Readonly<INameCheckProps>) {
        if (!isEqual(this.props, prevProps)) {
            this.retrieve();
        }
    }

    public componentWillUnmount() {
        this.controller?.abort();
    }

    public render() {
        if (!this.state.isUsed) {
            return null;
        }

        return React.createElement(
            this.props.htmlNativ ?? "div",
            {className: "form-text text-warning", id: "name-in-use"},
            <T>Warning: The specified name has already been used.</T>,
        );
    }

    private async retrieve() {
        this.controller?.abort();

        const {apiUrl, id, name, type} = this.props;
        if (!name) {
            this.setState({isUsed: false});
            return;
        }

        this.controller = new AbortController();
        const json: INameCheckRequest = {
            exclude: {id, type},
            name,
        };

        try {
            const response = await http
                .post(apiUrl, {json, signal: this.controller.signal})
                .json<INameCheckResponse>();

            this.setState({isUsed: response.isInUse});
        } catch {
            this.setState({isUsed: false});
        }
    }
}

export default NameChecker;
