import {boundMethod} from "autobind-decorator";
import React from "react";
import {Link} from "react-router-dom";

import {EDocIdPrefixes, IItemSelectionState, INestedBaseItem} from "./models";

import InlineCheckbox from "@toolbox/button-like/InlineCheckbox";
import {getSelection, isSelected} from "./SearchItem";
import {getDocIdInfo} from "./doc-type";

export interface IFractureSamplesProps {
    project: number;
    selection: IItemSelectionState;
    items: INestedBaseItem[] | undefined;

    onChange(selection: IItemSelectionState): void;
}

class FractureSamples extends React.PureComponent<IFractureSamplesProps> {
    @boundMethod
    public onSelected(e: React.ChangeEvent<HTMLInputElement>) {
        const specimens = this.getSpecimens();
        if (!specimens) {
            return;
        }

        const {checked, id} = e.target;
        const changed = specimens.find((x) => id.includes(x.id));
        if (!changed) {
            return;
        }

        changed.selected = checked;
        this.props.onChange(getSelection(specimens));
    }

    public render() {
        const specimens = this.getSpecimens();
        if (!specimens) {
            return null;
        }

        return specimens.map((x, i) => (
            <React.Fragment key={i}>
                <InlineCheckbox
                    key={x.id}
                    idSuffix={x.id}
                    className="mb-1"
                    checked={x.selected}
                    toggle={this.onSelected}
                >
                    <Link to={x.url}>{x.name}</Link>
                </InlineCheckbox>
                <br />
            </React.Fragment>
        ));
    }

    private getSpecimens() {
        const {project, selection, items} = this.props;
        const specimens = items?.filter((x) =>
            x.id.startsWith(EDocIdPrefixes.FracSamples),
        );
        if (!specimens?.length) {
            return null;
        }

        return specimens.map((x) => {
            const {url, id} = getDocIdInfo(x.id, project);

            return {id, url, name: x.name, selected: isSelected(id, selection)};
        });
    }
}

export default FractureSamples;
