import favicon from "../../public/favicon.ico";
import "./SessionExpiredNotification.module.scss";

import {faUser, faUserSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {FormattedMessage, IntlContext, IntlShape} from "react-intl";
import {Link} from "react-router-dom";

import {EExpireReasons} from "@/services/models";
import session from "@/services/session";
import {CLOSING_DELAY} from "@toolbox/models";

import Modal from "@toolbox/modals/Modal";
import Toast from "@toolbox/modals/Toast";
import T, {intl2Str} from "@translate/T";
import {debounce} from "lodash";
import Login from "./Login";

export function sepviewLogo(intl: IntlShape) {
    return (
        <img
            alt={intl2Str(intl, "SEPView logo")}
            className="mr-2"
            styleName="sepview-logo"
            src={favicon}
        />
    );
}

interface ISessionExpiredNotificationState {
    resumed: boolean;
    expired: boolean;
    showLogin: boolean;
}

class SessionExpiredNotification extends React.PureComponent<
    {},
    ISessionExpiredNotificationState
> {
    public readonly state: ISessionExpiredNotificationState = {
        expired: !session.token,
        resumed: false,
        showLogin: false,
    };

    private readonly ref = React.createRef<Modal>();
    private readonly close = debounce(
        this.dissmisResumed,
        0.25 * CLOSING_DELAY,
    );
    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = session.subscribe({
            expired: (reason) => {
                if (reason === EExpireReasons.LoggedOut) {
                    return;
                }

                this.setState({
                    expired: true,
                    resumed: false,
                    showLogin: false,
                });
            },
        });
    }

    public componentWillUnmount() {
        this.close.cancel();
        this.unsubscribe?.();
    }

    @boundMethod
    public showModal(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({showLogin: true});
    }

    @boundMethod
    public onLoginClosed() {
        if (!this.state.resumed) {
            // user dismissed login modal
            this.setState({showLogin: false});
            return;
        }

        // resumed
        this.setState({expired: false, showLogin: false});
        this.close(); // show resumed toast
    }

    @boundMethod
    public onAuthenitcated() {
        this.setState({resumed: true}, this.ref.current?.triggerClose);
    }

    @boundMethod
    public dissmisResumed() {
        this.setState({resumed: false});
    }

    @boundMethod
    public navigateToLogin() {
        window.location.reload();
    }

    public render() {
        const {expired, resumed, showLogin} = this.state;
        if (showLogin) {
            return this.renderLogin();
        }

        if (resumed) {
            return this.renderResumed();
        }

        if (expired) {
            return this.renderExpired();
        }

        return null;
    }

    private renderLogin() {
        const header = (
            <React.Fragment>
                <IntlContext.Consumer children={sepviewLogo} />
                <T>SEPView session resume</T>
            </React.Fragment>
        );

        return (
            <Modal
                ref={this.ref}
                header={header}
                headerClassName="bg-focus"
                afterClose={this.onLoginClosed}
            >
                <div className="modal-body">
                    <Login onAuthenticated={this.onAuthenitcated} />
                </div>
            </Modal>
        );
    }

    private renderResumed() {
        return (
            <Toast>
                <div className="toast-header text-success">
                    <FontAwesomeIcon
                        icon={faUser}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <strong className="mr-auto">
                        <T>Session resumed</T>
                    </strong>
                    <button
                        type="button"
                        className="close ml-1 my-auto"
                        aria-label="close"
                        onClick={this.dissmisResumed}
                    >
                        &times;
                    </button>
                </div>
                <div className="toast-body">
                    <T>You may need to perform your previous action again.</T>
                </div>
            </Toast>
        );
    }

    private renderExpired() {
        const resume = (
            <Link to="" onClick={this.showModal}>
                <T>resume</T>
            </Link>
        );
        const login = (
            <Link to="" onClick={this.navigateToLogin}>
                <T>Login page</T>
            </Link>
        );

        return (
            <Toast>
                <div className="toast-header text-warning">
                    <FontAwesomeIcon
                        icon={faUserSlash}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <strong>
                        <T>Session expired</T>
                    </strong>
                </div>
                <div className="toast-body">
                    <FormattedMessage
                        id="You can {resume} your session without losing your unsaved data, or navigate to {login}."
                        defaultMessage="You can {resume} your session without losing your unsaved data, or navigate to {login}."
                        values={{login, resume}}
                    />
                </div>
            </Toast>
        );
    }
}

export default SessionExpiredNotification;
