import EventHandlers from "@/services/handlers";

interface IEventHandler {
    aboutconfigWarningChanged?: () => void;
}

class WarningService {
    private readonly handlers = new EventHandlers<IEventHandler>();

    public aboutconfigTrigger() {
        this.handlers.publish((x) => x.aboutconfigWarningChanged?.());
    }

    public subscribe(handler: IEventHandler) {
        return this.handlers.register(handler);
    }
}

const warning = new WarningService();
export default warning;
