import {boundMethod} from "autobind-decorator";
import {kebabCase} from "lodash";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {ILocalizedText} from "@translate/models";

interface IInlineCheckboxProps {
    checked: boolean;
    idSuffix: string;

    checkboxClassNameOverwrite?: string;
    className?: string;
    dataTestId?: string;
    disabled?: boolean;
    doNotRenderCheckbox?: boolean;
    indeterminate?: boolean;
    otherLabelClassName?: string;
    required?: boolean;
    title?: ILocalizedText;

    // ToDo: remove event sending here and just send the toggle value
    toggle(e: React.ChangeEvent<HTMLInputElement>, id: string): void;
}

class InlineCheckbox extends React.PureComponent<IInlineCheckboxProps> {
    private readonly input = React.createRef<HTMLInputElement>();

    public componentDidMount() {
        this.setIndeterminateState();
    }

    public componentDidUpdate() {
        this.setIndeterminateState();
    }

    @boundMethod
    public toggle(e: React.ChangeEvent<HTMLInputElement>) {
        const {idSuffix, toggle} = this.props;
        toggle(e, idSuffix);
    }

    public render() {
        return <IntlContext.Consumer children={this.renderDiv} />;
    }

    @boundMethod
    private renderDiv(intl: IntlShape) {
        const {children, className, idSuffix, otherLabelClassName, title} =
            this.props;
        const id = `checkbox-${kebabCase(idSuffix)}`;

        if (!children) {
            return this.renderCheckbox(id);
        }

        return (
            <div
                className={`form-check form-check-inline${
                    className ? " " + className : ""
                }`}
                title={title?.(intl)}
            >
                {this.renderCheckbox(id)}
                <label
                    className={otherLabelClassName ?? "form-check-label"}
                    htmlFor={id}
                >
                    {children}
                </label>
            </div>
        );
    }

    private renderCheckbox(id: string) {
        const {
            checkboxClassNameOverwrite,
            checked,
            dataTestId,
            disabled,
            doNotRenderCheckbox,
            required,
            toggle,
        } = this.props;
        if (doNotRenderCheckbox) {
            return null;
        }

        return (
            <input
                ref={this.input}
                type="checkbox"
                id={id}
                className={
                    checkboxClassNameOverwrite ??
                    "form-check-input checkbox-primary"
                }
                aria-label="checkbox"
                checked={checked}
                data-testid={dataTestId ?? id}
                disabled={disabled}
                required={required}
                onChange={this.toggle}
            />
        );
    }

    private setIndeterminateState() {
        const {checked, indeterminate} = this.props;
        const input = this.input.current;
        if (indeterminate === undefined || !input) {
            return;
        }

        input.indeterminate = checked ? false : indeterminate;
    }
}

export default InlineCheckbox;
