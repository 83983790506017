import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";
import {Link} from "react-router-dom";

import {EDocIdPrefixes, IItemSelectionState, INestedBaseItem} from "./models";

import InlineCheckbox from "@toolbox/button-like/InlineCheckbox";
import {displayWavelength} from "@toolbox/display-blocks/Wavelength";
import {parseNumber} from "@translate/T";
import {getSelection, isSelected} from "./SearchItem";
import {getDocIdInfo} from "./doc-type";

export interface ICentrifugeSamplesProps {
    intl: IntlShape;
    project: number;
    selection: IItemSelectionState;
    items: INestedBaseItem[] | undefined;

    onChange(selection: IItemSelectionState): void;
}

class CentrifugeSamples extends React.PureComponent<ICentrifugeSamplesProps> {
    @boundMethod
    public onSelected(e: React.ChangeEvent<HTMLInputElement>) {
        const samples = this.getSamples();
        if (!samples) {
            return;
        }

        const {checked, id} = e.target;
        const changed = samples.find((x) => id.includes(x.id));
        if (!changed) {
            return;
        }

        changed.selected = checked;
        this.props.onChange(getSelection(samples));
    }

    public render() {
        const samples = this.getSamples();
        if (!samples) {
            return null;
        }

        const wavelengths = new Set(samples.map((x) => x.wavelength));
        const showWavelength = wavelengths.size > 1;

        return samples.map((x) => (
            <React.Fragment key={x.id}>
                <InlineCheckbox
                    idSuffix={x.id}
                    checked={x.selected}
                    className="mb-1"
                    toggle={this.onSelected}
                >
                    <Link to={x.url}>
                        {this.renderName(showWavelength, x.name, x.wavelength)}
                    </Link>
                </InlineCheckbox>
                <br />
            </React.Fragment>
        ));
    }

    private renderName(
        showWavelength: boolean,
        name: string | JSX.Element,
        wavelength: number,
    ) {
        const {intl} = this.props;

        let displayName = name;
        if (showWavelength) {
            displayName += ` (${displayWavelength(intl, wavelength)})`;
        }

        return displayName;
    }

    private getSamples() {
        const {project, selection, items} = this.props;
        const centItems = items?.filter((x) =>
            x.id.startsWith(EDocIdPrefixes.CentSamples),
        );
        if (!centItems?.length) {
            return null;
        }

        return centItems.map((x) => {
            const {url, id} = getDocIdInfo(x.id, project);
            const [_, __, ___, ____, wavelength] = x.id.split("/");

            return {
                id,
                wavelength: parseNumber(wavelength),
                name: x.name,
                selected: isSelected(id, selection),
                url,
            };
        });
    }
}

export default injectIntl(CentrifugeSamples);
