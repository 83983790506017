import {
    faAddressBook,
    faAddressCard,
    faCode,
    faCogs,
    faLaptopCode,
    faLifeRing,
    faNetworkWired,
    faSignOutAlt,
    faWrench,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {Link} from "react-router-dom";

import {EModuleLicenses} from "@/components/license/models";
import display from "@/services/display-name";
import {blob2DataURL, html2Blob} from "@/services/download";
import license from "@/services/license";
import {ERoles} from "@/services/models";
import session from "@/services/session";

import FormSubmit from "@toolbox/button-like/FormSubmit";
import RolesInfo from "@toolbox/modals/RolesInfo";
import T from "@translate/T";

interface IProfileState {
    displayName: string;
    showRoles: boolean;
    role: ERoles;

    supportUrl: string;
}

class Profile extends React.PureComponent<{}, IProfileState> {
    public readonly state: IProfileState = {
        displayName: "",
        showRoles: false,
        role: session.claims?.global ?? ERoles.None,
        supportUrl: "",
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        const update = async () => {
            const displayName = await display.getDisplayName(
                session.user ?? "",
            );

            this.setState({displayName});
        };

        const unDisplay = display.subscribe({
            namesChanged: update,
        });

        update();

        this.unsubscribe = () => {
            unDisplay();
        };
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public logout() {
        session.clear();
        this.setState({displayName: "", role: ERoles.None});
    }

    @boundMethod
    public showRoles() {
        this.setState({showRoles: !this.state.showRoles});
    }

    @boundMethod
    public async supportData() {
        const blob = await html2Blob(document.body);
        const screenshot = await blob2DataURL(blob);

        return {screenshot, userAgent: window.navigator.userAgent};
    }

    @boundMethod
    public async onChange(response: Response, after: () => void) {
        const resolve = (await response.json()) as {zipFileId: string};

        this.setState({supportUrl: "/" + resolve.zipFileId}, () => {
            after();
            this.setState({supportUrl: ""});
        });
    }

    public render() {
        const {displayName} = this.state;
        if (!displayName) {
            return null;
        }

        return (
            <React.Fragment>
                {this.renderRolesModal()}
                <li
                    className="nav-item dropdown"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                >
                    <Link
                        className="nav-link dropdown-toggle"
                        to=""
                        role="button"
                        id="profile-menu"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        {displayName}
                    </Link>

                    <div
                        aria-labelledby="profile-menu"
                        className="dropdown-menu dropdown-menu-right"
                    >
                        {this.renderUser()}

                        {this.renderAdmin()}

                        <Link to="/" onClick={this.logout}>
                            <button type="button" className="dropdown-item">
                                <FontAwesomeIcon
                                    icon={faSignOutAlt}
                                    fixedWidth={true}
                                    className="mr-1"
                                />
                                <T>Log out</T>
                            </button>
                        </Link>
                    </div>
                </li>
            </React.Fragment>
        );
    }

    private renderRolesModal() {
        const {showRoles} = this.state;
        if (!showRoles) {
            return null;
        }

        return (
            <RolesInfo
                globals={session.hasRole(ERoles.Administrator)}
                afterClose={this.showRoles}
            />
        );
    }

    private renderUser() {
        const {supportUrl} = this.state;

        return (
            <React.Fragment>
                <button
                    type="button"
                    className="dropdown-item"
                    onClick={this.showRoles}
                >
                    <FontAwesomeIcon
                        icon={faAddressBook}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Roles description</T>
                </button>

                <Link to="/account">
                    <button type="button" className="dropdown-item">
                        <FontAwesomeIcon
                            icon={faAddressCard}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>Edit account</T>
                    </button>
                </Link>

                <Link to="/preferences">
                    <button type="button" className="dropdown-item">
                        <FontAwesomeIcon
                            icon={faCogs}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>Preferences</T>
                    </button>
                </Link>

                <div className="dropdown-divider" />

                <Link to="/devices">
                    <button type="button" className="dropdown-item">
                        <FontAwesomeIcon
                            icon={faNetworkWired}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>Device connection</T>
                    </button>
                </Link>

                <FormSubmit
                    action={"/api/download/support" + supportUrl}
                    className="dropdown-item"
                    icon={faLifeRing}
                    method="GET"
                    name={<T>Download support data</T>}
                    idSuffix="download-support-data"
                    getJson={this.supportData}
                    onChange={this.onChange}
                />

                <div className="dropdown-divider" />
            </React.Fragment>
        );
    }

    private renderAdmin() {
        if (!session.hasRole(ERoles.Administrator)) {
            return null;
        }

        return (
            <React.Fragment>
                <Link to="/admin">
                    <button type="button" className="dropdown-item">
                        <FontAwesomeIcon
                            icon={faWrench}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>Administration</T>
                    </button>
                </Link>

                {this.renderSpocDeviceConfig()}

                <Link to="/license">
                    <button type="button" className="dropdown-item">
                        <FontAwesomeIcon
                            icon={faCode}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>License status</T>
                    </button>
                </Link>

                <div className="dropdown-divider" />
            </React.Fragment>
        );
    }

    private renderSpocDeviceConfig() {
        const {modules} = license.status;
        const count = modules.includes(EModuleLicenses.SpocCount);
        const size = modules.includes(EModuleLicenses.SpocSize);

        if (!(count || size)) {
            return null;
        }

        return (
            <Link to="/device_config">
                <button type="button" className="dropdown-item">
                    <FontAwesomeIcon
                        icon={faLaptopCode}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Device config</T>
                </button>
            </Link>
        );
    }
}

export default Profile;
