import {Axis, AxisDomain, AxisScale} from "d3-axis";

import {ILocalizedText} from "@translate/models";

export const CHART_16TO9_MAXWIDTH_ID = "chart-16-to-9-maxwidth";
export const DEFAULT_CHART_HEIGHT = 360;
export const MARGIN_HIGH = 50;
export const MARGIN_TEXT = 30;
export const MARGIN_CUVETTE = 20;
export const MARGIN_LOW = 10;
export const PRINT_REPORT_CHART_WIDTH = 700;
export const PRINT_REPORT_RECT = {
    height: (PRINT_REPORT_CHART_WIDTH / 16) * 9,
    width: PRINT_REPORT_CHART_WIDTH,
};

export const titleStyle: React.CSSProperties = {
    fontWeight: "bolder",
    textAnchor: "middle",
};

export enum EAxisScale {
    Lin,
    Log,
}

export enum EEdges {
    Left,
    Bottom,
    Right,
}

export enum ETickSpecifier {
    Percent = "%",
    Binary = "b",
    Exponential = "e",
    Normal = "f",
    Octa = "o",
    Scientific = "s",
    Hexa = "x",
}

export enum EResolution {
    None = "",
    Low = "low",
    Medium = "medium",
    High = "high",
}

// https://www.khronos.org/opengl/wiki/Primitive
export enum EPrimitive {
    Lines = "lines",
    LineStrip = "line strip",
    Triangles = "triangles",
    TriangleStrip = "triangle strip", // https://de.wikipedia.org/wiki/Triangle_Strip
    TriangleFan = "triangle fan",
    Points = "points",
}

// labels for chart
export interface IChartLabels {
    readonly x?: ILabel;
    readonly y?: ILabel;
    readonly yR?: ILabel;
}

export interface ILabel {
    name: ILocalizedText;
    unit?: ILocalizedText;
    scaling?: ILocalizedText; // scaling of axis (one of lin or log)
}

export interface IMargins {
    top: number;
    left: number;
    right: number;
    bottom: number;
}

export interface IBands {
    x: number;
    y: number;
    height: number;
    width: number;
}

export interface ISquareData {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
}

export interface IBandData {
    bands: IBands[];
    lines: ISquareData[];
}

export interface ILegendValue {
    decimals: number;
    transform(x: number, y: number): number;
    title: ILocalizedText;
    unit?: ILocalizedText;
    unitBelow?: boolean;
}

export interface IAnalyzeData {
    x: number[];
    y: number[];
    x2?: number[];
    y2?: number[];
    y2Log?: number[];
}

export interface IStalableValue {
    isStale?: boolean; // indicate if this value is staled
}

export type IBrushSelection2D = [[number, number], [number, number]];
export type IBrushSelection1D = [number, number];
export type IAxis = Axis<AxisDomain>;
export type IAxisScale = AxisScale<number> | AxisScale<string>;
export type IStalableAnalyzeData = IAnalyzeData & IStalableValue;
