import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import {extent} from "d3-array";
import React from "react";

import {EPropertyTypes, IMaterialFunctionModel} from "../../models";

import MathEvaluator from "@/components/math/math-evaluator";
import ValidatedForm from "@toolbox/button-like/ValidatedForm";
import Modal, {CancelButton} from "@toolbox/modals/Modal";
import T, {parseNumber} from "@translate/T";
import {
    getEnergyWavelength,
    getFormulaUnit,
    usesEnergy,
    usesTemperature,
    usesWavelength,
} from "../FunctionRow";
import {getFunctionTitle} from "../MaterialFunctions";
import FunctionTest from "./FunctionTest";
import MaterialInputs from "./MaterialInputs";

export interface IEditorModalProps {
    type: EPropertyTypes;
    value: IMaterialFunctionModel;
    extra?: IMaterialFunctionModel;

    onAbort(): void;
    onSave(value: IMaterialFunctionModel, extra?: IMaterialFunctionModel): void;
    getDensity4MassCoeff?(): number | undefined;
}

interface IEditorModalState extends IMaterialFunctionModel {
    massCoeff?: string;

    sync: boolean;
    isValid: boolean;
    shouldSave: boolean;
}

class EditorModal extends React.PureComponent<
    IEditorModalProps,
    IEditorModalState
> {
    public readonly state: IEditorModalState = {
        formula: this.props.value.formula,
        massCoeff: this.props.extra?.formula,
        notes: this.props.value.notes,

        maxTemperature: this.props.value.maxTemperature,
        maxWavelength: !usesEnergy(this.props.type)
            ? this.props.value.maxWavelength
            : this.props.value.minWavelength,
        minTemperature: this.props.value.minTemperature,
        minWavelength: !usesEnergy(this.props.type)
            ? this.props.value.minWavelength
            : this.props.value.maxWavelength,

        sync: false,
        isValid: true, // on load from server it will always be valid
        shouldSave: false,
    };

    private readonly ref = React.createRef<Modal>();
    private readonly evaluator: MathEvaluator = new MathEvaluator(
        usesTemperature(this.props.type),
        usesWavelength(this.props.type),
        usesEnergy(this.props.type),
    );

    @boundMethod
    public submit() {
        this.setState({shouldSave: true}, this.ref.current?.triggerClose);
    }

    @boundMethod
    public afterClose() {
        const {onAbort, onSave, extra} = this.props;
        const {
            formula,
            massCoeff,
            maxTemperature,
            minTemperature,
            notes,
            shouldSave,
        } = this.state;
        let {maxWavelength, minWavelength} = this.state;

        if (!shouldSave) {
            onAbort();
            return;
        }

        if (maxWavelength !== undefined && minWavelength !== undefined) {
            [minWavelength, maxWavelength] = extent([
                minWavelength,
                maxWavelength,
            ]);
        }

        let _extra: IMaterialFunctionModel | undefined;
        if (extra && massCoeff !== undefined) {
            _extra = {
                formula: massCoeff,
                maxTemperature: extra.maxTemperature,
                maxWavelength,
                minTemperature: extra.minTemperature,
                minWavelength,
                notes,
            };
        }

        onSave(
            {
                formula: formula.trim(),
                maxTemperature,
                maxWavelength,
                minTemperature,
                minWavelength,
                notes,
            },
            _extra,
        );
    }

    @boundMethod
    public setFormula(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        const {getDensity4MassCoeff} = this.props;
        const {sync} = this.state;
        let {massCoeff} = this.state;

        let formula = e.target.value;
        if (usesEnergy(this.props.type)) {
            formula = formula.replace(/E/g, "L");
        }

        // translate number to english
        formula = formula.replace(/,/g, ".");
        const isValid = this.evaluator.setFormula(formula.trim()).isValid();

        if (sync) {
            const density = getDensity4MassCoeff?.();
            if (density && !isNaN(parseNumber(formula))) {
                massCoeff = this.calcMassCoeff(formula, density);
            }
        }

        this.setState({formula, massCoeff, isValid});
    }

    @boundMethod
    public setMassCoeff(value: number | null) {
        const {getDensity4MassCoeff} = this.props;
        const {sync} = this.state;
        let {formula} = this.state;

        const massCoeff = value === null ? "" : value.toString();
        if (sync) {
            const density = getDensity4MassCoeff?.();
            if (density) {
                formula = this.calcAttenuation(massCoeff, density);
            }
        }

        this.setState({massCoeff, formula});
    }

    @boundMethod
    public setMassCoeffCheck(e: React.ChangeEvent<HTMLInputElement>) {
        const {getDensity4MassCoeff} = this.props;
        let {formula, massCoeff} = this.state;

        const sync = e.target.checked;
        if (sync) {
            const density = getDensity4MassCoeff?.();

            if (density) {
                // value has higher prio to stay
                if (formula && !isNaN(parseNumber(formula))) {
                    massCoeff = this.calcMassCoeff(formula, density);
                } else if (massCoeff) {
                    formula = this.calcAttenuation(massCoeff, density);
                }
            }
        }

        this.setState({sync, formula, massCoeff});
    }

    @boundMethod
    public setNumberValue(value: number, id: string) {
        // captions are important
        if (id.includes("Wavelength")) {
            value = getEnergyWavelength(value, this.props.type, true);
        }

        this.setState({[id]: value} as unknown as IEditorModalState);
    }

    @boundMethod
    public setNotes(e: React.ChangeEvent<HTMLTextAreaElement>) {
        e.preventDefault();

        this.setState({notes: e.target.value});
    }

    public render() {
        const {type} = this.props;
        const {
            formula,
            massCoeff,
            maxTemperature,
            maxWavelength,
            minTemperature,
            minWavelength,
            notes,
            isValid,
            sync,
        } = this.state;

        return (
            <Modal
                ref={this.ref}
                header={this.renderHeader()}
                size="lg"
                afterClose={this.afterClose}
            >
                <ValidatedForm
                    suffixId="property-editor"
                    onSubmit={this.submit}
                >
                    <div className="modal-body" id="property-modal">
                        <MaterialInputs
                            type={type}
                            formula={formula}
                            massCoeff={massCoeff}
                            maxTemperature={maxTemperature}
                            maxWavelength={maxWavelength}
                            minTemperature={minTemperature}
                            minWavelength={minWavelength}
                            notes={notes}
                            isValid={isValid}
                            sync={sync}
                            setFormula={this.setFormula}
                            setNotes={this.setNotes}
                            setMassCoeff={this.setMassCoeff}
                            setMassCoeffCheck={this.setMassCoeffCheck}
                            setNumberValue={this.setNumberValue}
                        />

                        <FunctionTest
                            type={type}
                            formula={formula.trim()}
                            evaluator={this.evaluator}
                            minWavelength={minWavelength}
                            maxWavelength={maxWavelength}
                            minTemperature={minTemperature}
                            maxTemperature={maxTemperature}
                        />
                    </div>

                    {this.renderButtons()}
                </ValidatedForm>
            </Modal>
        );
    }

    private renderHeader() {
        const {type} = this.props;

        return (
            <React.Fragment>
                <T>Property editor:</T> {getFunctionTitle(type)}
                {getFormulaUnit(type)}
            </React.Fragment>
        );
    }

    private renderButtons() {
        return (
            <div className="modal-footer">
                <button
                    type="submit"
                    id="apply-button"
                    className="btn btn-primary mr-1"
                >
                    <FontAwesomeIcon
                        icon={faEdit}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Apply</T>
                </button>

                <CancelButton>
                    <T>Close</T>
                </CancelButton>
            </div>
        );
    }

    private calcMassCoeff(attenuation: string, density: number) {
        if (attenuation === "") {
            return "";
        }

        return (parseNumber(attenuation) / density).toString();
    }

    private calcAttenuation(massCoeff: string, density: number) {
        if (massCoeff === "") {
            return "";
        }

        return (parseNumber(massCoeff) * density).toString();
    }
}

export default EditorModal;
