import {boundMethod} from "autobind-decorator";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import parseISO from "date-fns/parseISO";
import ky from "ky";
import {debounce} from "lodash";
import {
    compressToEncodedURIComponent,
    decompressFromEncodedURIComponent,
} from "lz-string";

import display from "@/services/display-name";
import EventHandlers from "@/services/handlers";
import license from "@/services/license";
import {EExpireReasons, ERoles, SESSION_KEY} from "@/services/models";
import networkDevices from "@/services/network-devices";
import preferences from "@/services/preferences";
import realtime from "@/services/realtime";
import signal from "@/services/signal";

import {bitwiseAND} from "@toolbox/functions/bitwise";
import aCsvError from "./acsv-error";

interface IEventHandler {
    expired?(reason: EExpireReasons): void;
    authenticated?(session: ISession): void;
}

export interface ISessionClaims {
    global: ERoles; // global role
    projects: {[project: number]: ERoles}; // project roles
    hiddens: {[project: number]: ERoles}; // hidden project roles, that will not include normal roles
}

export interface ISession {
    user: string; // user ID
    token: string; // authentication token
    expiry: string; // token expiry time in UTC
    claims: ISessionClaims; // session effective claims
}

export class SessionService {
    private session?: ISession; // Gets the logged in user session, only available if user has logged in

    private readonly handlers = new EventHandlers<IEventHandler>();
    private abortAutoLogout?: () => void;

    public constructor() {
        this.session = SessionService.loadSession();

        if (this.session) {
            this.scheduleRefresh();
        }
    }

    private static isExpired(value: ISession) {
        return parseISO(value.expiry) < new Date();
    }

    private static loadSession(): ISession | undefined {
        const json = localStorage.getItem(SESSION_KEY);
        if (!json) {
            return;
        }

        try {
            const value = JSON.parse(
                decompressFromEncodedURIComponent(json),
            ) as ISession;
            if (SessionService.isExpired(value)) {
                return;
            }

            return value;
        } catch {
            localStorage.removeItem(SESSION_KEY);
        }
    }

    public get hasSession() {
        return this.session !== undefined;
    }

    public get claims() {
        return this.session?.claims;
    }

    public get token() {
        return this.session?.token;
    }

    public get user() {
        return this.session?.user;
    }

    @boundMethod
    public override(global: ERoles) {
        const local = this.session;
        if (!local) {
            return;
        }

        const claims: ISessionClaims = {...local.claims, global};
        const newValue: ISession = {...local, claims};

        this.setSession(newValue);
        window.location.reload();
    }

    public async setSession(value: ISession | undefined) {
        if (!value) {
            // fallback for Startup.tsx
            value = this.session;
            if (!value) {
                return;
            }
        }

        if (SessionService.isExpired(value)) {
            this.expired();
            return;
        }

        await this.login(value);
        this.handlers.publish((x) => x.authenticated?.(value!));
    }

    public async expired() {
        await this.logout();
        this.handlers.publish((x) => x.expired?.(EExpireReasons.Expired));
    }

    public async clear() {
        await this.logout();
        this.handlers.publish((x) => x.expired?.(EExpireReasons.LoggedOut));
    }

    public clearLocalStorage() {
        localStorage.clear();
    }

    // determines if the logged in user has the specified role
    public hasRole(role: ERoles, project?: number) {
        const claims = this.claims;
        if (!claims) {
            return false;
        }

        // global admin can do everything.
        if (claims.global >= ERoles.GlobalAdministrator) {
            return true;
        }

        // special project roles have prio, normal claims will be "overwritten"
        if (project !== undefined) {
            const assigned: ERoles | undefined = claims.hiddens[project];
            if (assigned !== undefined) {
                return bitwiseAND(assigned, role) === role;
            }
        }

        if (bitwiseAND(claims.global, role) === role) {
            return true;
        }

        if (!project) {
            return false;
        }

        return (
            bitwiseAND(claims.projects[project] ?? ERoles.None, role) === role
        );
    }

    public canEditThisUser(affectedUser: ERoles) {
        if (session.hasRole(ERoles.GlobalAdministrator)) {
            return true;
        }

        return affectedUser < ERoles.GlobalAdministrator;
    }

    public subscribe(handler: IEventHandler) {
        return this.handlers.register(handler);
    }

    public async refreshToken(existing?: string) {
        const token = this.session?.token ?? existing;
        if (!token) {
            return;
        }

        try {
            const response = await ky
                .get("/api/auth/refresh", {
                    cache: "no-cache",
                    headers: {Authorization: "Token " + token},
                })
                .json<ISession>();

            if (SessionService.isExpired(response)) {
                this.expired();
                return;
            }

            this.session = response;
            this.setSession(response);
        } catch {
            this.clear();
            return;
        }
    }

    private async login(value: ISession) {
        this.session = value;
        localStorage.setItem(
            SESSION_KEY,
            compressToEncodedURIComponent(JSON.stringify(value)),
        );
        this.scheduleRefresh();

        // services ready
        await Promise.all([
            license.retrieveLicense(),
            aCsvError.retrieve(),
            networkDevices.retrieveDevices(),
            networkDevices.retrieveConfig(),
            preferences.retrieve(),
            realtime.retrieve(),
            signal.connect2Backend(),
        ]);
    }

    private async logout() {
        this.session = undefined;
        localStorage.removeItem(SESSION_KEY);
        this.abortAutoLogout?.();

        // services and data reset, but never notify user !!
        display.deleteAll();
        license.silentReset();
        networkDevices.silentReset();
        preferences.silentReset();
        realtime.silentReset();
        signal.disconnect2Backend();
    }

    private scheduleRefresh() {
        this.abortAutoLogout?.();
        const active = this.session!;
        const timeout = differenceInMilliseconds(
            parseISO(active.expiry),
            new Date(),
        );

        const timer = debounce(() => this.refreshToken(), timeout);
        this.abortAutoLogout = timer.cancel;
        timer();
    }
}

const session = new SessionService();
export default session;
