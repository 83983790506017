import {boundMethod} from "autobind-decorator";
import {kebabCase} from "lodash";
import React from "react";
import {Link} from "react-router-dom";

import {
    EInvalidMaterialProperty,
    EPropertyTypes,
    IMaterial,
    IMaterialDetailModel,
} from "@/components/materials/models";
import http from "@/services/http";
import {ILocalizedText} from "@translate/models";
import {
    ICalcMaterial,
    ICalcMaterialRequest,
    ICalcMaterialResponse,
    IMaterialDetailRequest,
    IMaterialDetailResponse,
} from "./models";

import Tooltip from "@toolbox/building-blocks/Tooltip";
import {bitwiseAND} from "@toolbox/functions/bitwise";
import {parseNaNText} from "@toolbox/functions/requests";
import T from "@translate/T";
import {getFunctionTitle} from "../property-editor/MaterialFunctions";
import MaterialModal from "./MaterialModal";

export async function loadCalcMaterialData(
    materialId: number,
    temperature?: number,
    wavelength?: number,
): Promise<ICalcMaterial> {
    const json: ICalcMaterialRequest = {
        temperature,
        wavelength,
    };

    try {
        const response = await http
            .post(`/api/material/${materialId}/calc`, {json})
            .json<ICalcMaterialResponse>();

        return {
            materialId,
            viscosity: parseNaNText(response.viscosity),
            density: parseNaNText(response.density),
            rpri: parseNaNText(response.rpri),
            ipri: parseNaNText(response.ipri),
            attenuation: parseNaNText(response.attenuation),
        };
    } catch {
        return {
            materialId,
            viscosity: NaN,
            density: NaN,
            rpri: NaN,
            ipri: NaN,
            attenuation: NaN,
        };
    }
}

export function getMaterialWarning(
    incomplete: EInvalidMaterialProperty | boolean,
    outOfBound: EInvalidMaterialProperty = EInvalidMaterialProperty.None,
    sameAttenuation?: boolean,
) {
    if (incomplete) {
        if (typeof incomplete === "boolean") {
            return <T>The material detail definitions are incomplete.</T>;
        }

        return (
            <React.Fragment>
                <T>The following material detail definitions are incomplete:</T>
                {getPropertyNames(incomplete)}
            </React.Fragment>
        );
    }

    if (outOfBound) {
        return (
            <React.Fragment>
                <T>
                    Parameters are out of range for the following material
                    properties:
                </T>
                {getPropertyNames(outOfBound)}
            </React.Fragment>
        );
    }

    if (sameAttenuation) {
        return (
            <T>
                Selection of materials results leads to the same attenuation
                coefficients. Different coefficients are required for a
                successful calculation.
            </T>
        );
    }

    return null;
}

function getPropertyNames(invalid: EInvalidMaterialProperty) {
    const fragment = (item: JSX.Element) => (
        <React.Fragment>
            <br />
            {item}
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {!!bitwiseAND(invalid, EInvalidMaterialProperty.Viscosity) &&
                fragment(getFunctionTitle(EPropertyTypes.Viscosity))}
            {!!bitwiseAND(invalid, EInvalidMaterialProperty.Density) &&
                fragment(getFunctionTitle(EPropertyTypes.Density))}
            {!!bitwiseAND(invalid, EInvalidMaterialProperty.Rpri) &&
                fragment(getFunctionTitle(EPropertyTypes.Rpri))}
            {!!bitwiseAND(invalid, EInvalidMaterialProperty.Ipri) &&
                fragment(getFunctionTitle(EPropertyTypes.Ipri))}
            {!!bitwiseAND(invalid, EInvalidMaterialProperty.Attenuation) &&
                fragment(getFunctionTitle(EPropertyTypes.Attenuation))}
        </React.Fragment>
    );
}

export interface IMaterialSelectorProps {
    id: string;

    selected?: IMaterial;

    temperature?: number;
    wavelength?: number;

    calcError?: EInvalidMaterialProperty;
    className?: string;
    materialWarning?: JSX.Element | null;
    optional?: boolean; // set to true to allow no material
    showAll?: boolean;
    suppressTooltip?: boolean;
    triggerId?: string;

    onChange(material?: IMaterial): void;
    setOutOfBound?(outOfBound: EInvalidMaterialProperty): void;
    getOverrideModalTitle?(): ILocalizedText;
}

interface IMaterialSelectorState {
    material?: IMaterialDetailModel;
    outOfBound?: EInvalidMaterialProperty;

    showModal?: boolean;
}

class MaterialSelector extends React.PureComponent<
    IMaterialSelectorProps,
    IMaterialSelectorState
> {
    public readonly state: IMaterialSelectorState = {};

    public async componentDidMount() {
        await this.loadSelected();
    }

    public async componentDidUpdate() {
        await this.loadSelected();
    }

    @boundMethod
    public showModal(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({showModal: true});
    }

    @boundMethod
    public onClose() {
        this.setState({showModal: false});
    }

    public render() {
        const {
            className,
            selected,
            calcError,
            materialWarning,
            id,
            triggerId,
            suppressTooltip,
        } = this.props;

        let tooltip = null;
        let _className = "form-text text-middle m-0";
        if (className) {
            _className += " " + className;
        }

        if (!suppressTooltip) {
            // if this gets true, we will never enter this, hence no tooltip
            if (calcError) {
                tooltip = this.getErrorTooltip(calcError);
                _className += " text-danger is-invalid";
            } else if (!selected || materialWarning) {
                tooltip = materialWarning ?? this.getWarningTooltip();
                _className += " text-warning is-invalid-warning";
            }
        }

        return (
            <React.Fragment>
                <Tooltip content={tooltip} triggerId={triggerId}>
                    <Link
                        className={_className}
                        id={kebabCase(id)}
                        to=""
                        onClick={this.showModal}
                    >
                        {selected?.name ?? <T>Press to select...</T>}
                    </Link>
                </Tooltip>

                {this.renderModal()}
            </React.Fragment>
        );
    }

    private renderModal() {
        const {
            getOverrideModalTitle,
            id,
            materialWarning,
            onChange,
            optional,
            selected,
            showAll,
            temperature,
            wavelength,
        } = this.props;
        const {showModal, material} = this.state;
        if (!showModal) {
            return null;
        }

        return (
            <MaterialModal
                id={id}
                selected={selected}
                material={material}
                temperature={temperature}
                wavelength={wavelength}
                showAll={showAll}
                materialWarning={materialWarning}
                optional={optional}
                overrideModalTitle={getOverrideModalTitle?.()}
                onClose={this.onClose}
                onChange={onChange}
            />
        );
    }

    private getErrorTooltip(invalid: EInvalidMaterialProperty) {
        return (
            <React.Fragment>
                <T>
                    Your material failed during calculation. Check the
                    following:
                </T>
                {getPropertyNames(invalid)}
            </React.Fragment>
        );
    }

    private getWarningTooltip() {
        return <T>You need to select a material.</T>;
    }

    private async loadSelected() {
        const {selected, temperature, wavelength, setOutOfBound} = this.props;
        const materialId = selected?.id;
        if (materialId === undefined) {
            this.setState({material: undefined}, () =>
                setOutOfBound?.(EInvalidMaterialProperty.None),
            );
            return;
        }

        if (materialId === this.state.material?.id) {
            return;
        }

        const json: IMaterialDetailRequest = {
            temperature,
            wavelength,
        };

        try {
            const response = await http
                .post(`/api/material/select/${materialId}`, {json})
                .json<IMaterialDetailResponse>();

            this.setState({material: response.material}, () =>
                setOutOfBound?.(response.outOfBound),
            );
        } catch {
            this.setState({material: undefined}, () =>
                setOutOfBound?.(EInvalidMaterialProperty.None),
            );
        }
    }
}

export default MaterialSelector;
