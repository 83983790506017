import {faUpload} from "@fortawesome/free-solid-svg-icons";

import {boundMethod} from "autobind-decorator";
import {kebabCase} from "lodash";
import React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import http from "@/services/http";
import license from "@/services/license";
import {ILocalizedText} from "@translate/models";
import {ELicenseUploadErrors, ILicenseResponse} from "./models";

import InlineCheckbox from "@toolbox/button-like/InlineCheckbox";
import SaveButton from "@toolbox/button-like/SaveButton";
import ValidatedForm from "@toolbox/button-like/ValidatedForm";
import Card from "@toolbox/design/Card";
import Modal from "@toolbox/modals/Modal";
import TextInput from "@toolbox/nativ-inputs/TextInput";
import T, {intl2Str} from "@translate/T";
import UploadResult from "./UploadResult";

interface ILicenseActivationProps {
    installationId: string;
    serialKey: string;
}

interface ILicenseActivationState {
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;

    privacyPolicy: boolean;
    show: boolean;

    error: ELicenseUploadErrors;
}

class LicenseActivation extends React.PureComponent<
    ILicenseActivationProps,
    ILicenseActivationState
> {
    public readonly state: ILicenseActivationState = {
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        privacyPolicy: false,
        show: false,
        error: ELicenseUploadErrors.None,
    };

    private readonly saveButton = React.createRef<SaveButton>();

    private get contentType() {
        return {"Content-Type": "application/x-www-form-urlencoded"};
    }

    @boundMethod
    public onChange(value: string, id: keyof ILicenseActivationState) {
        this.setState({[id]: value} as unknown as ILicenseActivationState);
    }

    @boundMethod
    public onToggle(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({privacyPolicy: e.target.checked});
    }

    @boundMethod
    public showModal(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({show: true});
    }

    @boundMethod
    public closeModal() {
        this.setState({show: false});
    }

    @boundMethod
    public submitForm() {
        this.saveButton.current?.submitForm();
    }

    public render() {
        return (
            <Card title={<T>Online Activation</T>}>
                <div className="card-body">{this.renderDetails()}</div>
                {this.renderModal()}
            </Card>
        );
    }

    private renderDetails() {
        const {firstName, lastName, companyName, email, privacyPolicy} =
            this.state;
        const emailPlaceholder: ILocalizedText = (intl) =>
            intl2Str(intl, "john.doe@mail.de");

        return (
            <ValidatedForm
                suffixId="online-activation"
                noFocus={true}
                onSubmit={this.submitForm}
            >
                <div className="form-row">
                    <div className="col-sm-6 mb-2">
                        <label
                            className="mb-1"
                            htmlFor={kebabCase("firstName")}
                        >
                            <T>First name</T>
                        </label>
                        <TextInput
                            id="firstName"
                            required={true}
                            text={firstName}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label className="mb-1" htmlFor={kebabCase("lastName")}>
                            <T>Last name</T>
                        </label>
                        <TextInput
                            id="lastName"
                            required={true}
                            text={lastName}
                            onChange={this.onChange}
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="col-sm-6 mb-2">
                        <label
                            className="mb-1"
                            htmlFor={kebabCase("companyName")}
                        >
                            <T>Company name</T>
                        </label>
                        <TextInput
                            id="companyName"
                            required={true}
                            text={companyName}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label className="mb-1" htmlFor={kebabCase("email")}>
                            <T>Email</T>
                        </label>
                        <TextInput
                            id="email"
                            placeholder={emailPlaceholder}
                            required={true}
                            text={email}
                            onChange={this.onChange}
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="col-lg space-nowrap my-lg-auto mb-2">
                        <SaveButton
                            ref={this.saveButton}
                            id="online-activation"
                            otherName={<T>Activate SEPView license Online</T>}
                            otherIcon={faUpload}
                            onSave={this.activation}
                        />
                    </div>

                    <div className="col-lg">
                        <InlineCheckbox
                            idSuffix="privacy-policy"
                            checked={privacyPolicy}
                            required={true}
                            toggle={this.onToggle}
                        >
                            <FormattedMessage
                                id="I have read and accept the {privacyPolicy}."
                                defaultMessage="I have read and accept the {privacyPolicy}."
                                values={{
                                    privacyPolicy: (
                                        <Link to="" onClick={this.showModal}>
                                            <T>privacy policy</T>
                                        </Link>
                                    ),
                                }}
                            />
                        </InlineCheckbox>
                    </div>
                </div>

                {this.renderError()}
            </ValidatedForm>
        );
    }

    private renderError() {
        const {error} = this.state;
        if (error === ELicenseUploadErrors.None) {
            return null;
        }

        return (
            <ul className="list-unstyled mt-2">
                <UploadResult value={{name: "", result: error}} />
            </ul>
        );
    }

    private renderModal() {
        const {show} = this.state;
        if (!show) {
            return null;
        }

        return (
            <Modal
                header={<T>Privacy policy information</T>}
                size="xl"
                afterClose={this.closeModal}
            >
                <div className="modal-body">
                    <T>
                        For the purpose of validating and verifying the contact
                        with you as our customer when activating the software
                        license SEPView we need the following data from you:
                    </T>
                    <ul className="my-2">
                        <li>
                            <T>Name</T>
                        </li>
                        <li>
                            <T>Company name</T>
                        </li>
                        <li>
                            <T>Email address</T>
                        </li>
                    </ul>
                    <T>
                        This data is assigned to your individual analysis device
                        when the software is activated.
                    </T>
                    <br />
                    <T>
                        We use the data to contact you, to verify your identity
                        in case of queries, to ensure that the measurement
                        software is not used without authorization and to
                        provide support. The data is collected, processed and
                        stored for this purpose.
                    </T>
                    <br />
                    <T>
                        You can always request information about privacy and
                        about the data we have stored about you. Contact to:
                    </T>
                    <br />
                    <ul className="mt-2">
                        <li>
                            <T>via e-mail: datenschutz@lum-gmbh.de</T>
                        </li>
                        <li>
                            <T>
                                or postal: LUM GmbH, Justus-von-Liebig-Straße 3,
                                12489 Berlin
                            </T>
                        </li>
                    </ul>
                </div>
            </Modal>
        );
    }

    // @boundMethod
    // private async submit() {
    //     const {installationId, serialKey} = this.props;
    //     const {firstName, lastName, companyName, email} = this.state;

    //     const json: IAuthTokenRequest = {
    //         username: "nathanritter@outlook.de",
    //         password: "yAqwsx123.",
    //     };

    //     let cookie = "";
    //     try {
    //         const response = await http
    //             .post("/license/account/authToken", {
    //                 headers: {...this.contentType},
    //                 json,
    //             })
    //             .json<IAuthTokenResponse>();

    //         cookie = response.CookieName + "=" + response.CookieValue;
    //     } catch {
    //         return this.serverActivation(ELicenseUploadErrors.Authentication);
    //     }

    //     try {
    //         const searchParams = new URLSearchParams({
    //             installationId,
    //             serialKey,
    //             firstName,
    //             lastName,
    //             companyName,
    //             email,
    //         });

    //         const response = await http.post("/license/license/activate", {
    //             headers: {...this.contentType, cookie},
    //             body: searchParams.toString(),
    //         });
    //         const licenseFile = await response.text();

    //         const filename =
    //             response.headers
    //                 .get("content-disposition")
    //                 ?.match("(?<=filename=).*xml")?.[0] ?? "license.xml";
    //         const file = new File([licenseFile], filename, {
    //             type: "text/xml",
    //             lastModified: new Date().getTime(),
    //         });

    //         const _response = await onLicenseUpload([file]);
    //         if (!_response) {
    //             this.setState({error: ELicenseUploadErrors.CantSave});
    //             return false;
    //         }

    //         license.load(_response.license);

    //         return true;
    //     } catch {
    //         return this.serverActivation(ELicenseUploadErrors.ServerError);
    //     }
    // }

    @boundMethod
    private async activation() {
        const {installationId, serialKey} = this.props;
        const {firstName, lastName, companyName, email} = this.state;

        const searchParams = new URLSearchParams({
            installationId,
            serialKey,
            firstName,
            lastName,
            companyName,
            email,
        });

        try {
            const response = await http
                .get("/api/license/activation", {searchParams, timeout: false})
                .json<ILicenseResponse>();

            license.load(response);

            return true;
        } catch (error) {
            if (error instanceof http.HTTPError) {
                if (error.response.status === 404) {
                    this.setState({
                        error: ELicenseUploadErrors.ActivationServerNotReachable,
                    });
                }

                if (error.response.status === 406) {
                    this.setState({
                        error: ELicenseUploadErrors.OnlineActivationFailed,
                    });
                }
            }

            return false;
        }
    }
}

export default LicenseActivation;
