import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {Link} from "react-router-dom";

import {EModules} from "@/models";
import {IModuleGroup} from "@shared/analysis/analyze-button/models";
import {IModuleInfo} from "@toolbox/display-blocks/models";
import {EServerId} from "../../models";

import ModuleIcon, {getModuleInfo} from "@toolbox/display-blocks/ModuleIcon";

export interface IAnalyzeStandardProps {
    intl: IntlShape;
    project: number;
    serverId: EServerId;
    modules: IModuleGroup[];
    columns: number;

    hrefTarget?: string;

    getTargets(engine?: EModules): string;
    onClick(): void;
}

class AnalyzeStandard extends React.PureComponent<IAnalyzeStandardProps> {
    public render() {
        const {modules, columns} = this.props;
        const last = modules.length - 1;

        return modules.map((group, i) => (
            <div key={group.id} className={last !== i ? "mb-2" : undefined}>
                <h5 className="header border-bottom">{group.header}</h5>
                <ul className="list-unstyled" style={{columns}}>
                    {this.renderButtons(group)}
                </ul>
            </div>
        ));
    }

    private renderButtons(group: IModuleGroup) {
        const {columns} = this.props;
        const last = Math.floor(group.modules.length - 1 / columns);

        return group.modules.map((engine, i) =>
            this.renderButton(
                engine,
                getModuleInfo(engine),
                last === Math.floor(i / columns),
            ),
        );
    }

    private renderButton(
        engine: EModules,
        info: IModuleInfo,
        lastRow: boolean,
    ) {
        const {onClick, intl, hrefTarget} = this.props;

        return (
            <li key={engine} className={!lastRow ? "mb-1" : undefined}>
                <Link
                    id={`link-to-${engine}`}
                    data-testid={`link-to-${engine}`}
                    onClick={onClick}
                    target={hrefTarget}
                    to={this.getUrl(engine)}
                >
                    <ModuleIcon icon={info.icon} />
                    <span className="align-middle ml-1">{info.type(intl)}</span>
                </Link>
            </li>
        );
    }

    private getUrl(engine: EModules) {
        const {project, getTargets, serverId} = this.props;
        const targets = getTargets(engine);
        return `/project/${project}/${serverId}/analyze?engine=${engine}&${targets}`;
    }
}

export default injectIntl(AnalyzeStandard);
