import React from "react";

import {WARNING_KEY} from "../../services/models";

import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import SessionCheck from "../../SessionCheck";
import ACsvErrorWarning from "./ACsvErrorWarning";
import DiskSpaceWarning from "./hard-drice-space/DiskSpaceWarning";
import LicenseWarning from "./LicenseWarning";
import UnlicensedDevice from "./UnlicensedDevice";

class Warnings extends React.PureComponent {
    public render() {
        return (
            <SessionCheck>
                {!localStorage.getItem(WARNING_KEY) && (
                    <ContainerCol>
                        <LicenseWarning />
                        <UnlicensedDevice />
                        <DiskSpaceWarning />
                        <ACsvErrorWarning />
                    </ContainerCol>
                )}
            </SessionCheck>
        );
    }
}

export default Warnings;
