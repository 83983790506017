import {boundMethod} from "autobind-decorator";
import subDays from "date-fns/subDays";
import subHours from "date-fns/subHours";
import subMonths from "date-fns/subMonths";
import subWeeks from "date-fns/subWeeks";
import subYears from "date-fns/subYears";
import {debounce} from "lodash";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {RouteComponentProps} from "react-router";

import http from "@/services/http";
import ParamsParser from "@/services/params";
import {pagingDefault} from "@toolbox/building-blocks/models";
import {IDLE_DELAY} from "@toolbox/models";
import {ESearchQueries} from "../models";
import {
    ESortColumns,
    ETimeRange,
    IMatchCategory,
    IProjectSearchResult,
    ISearchBoxValue,
    ISearchDocumentModel,
    ISelectedItem,
} from "./models";

import Pager from "@toolbox/building-blocks/Pager";
import ErrorAlert from "@toolbox/design/ErrorAlert";
import ContainerActivity from "@toolbox/display-blocks/ContainerActivity";
import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import {sortObject} from "@toolbox/functions/object-iterator";
import T, {intl2Str, parseNumber} from "@translate/T";
import ProjectAudit from "./ProjectAudit";
import RecentMeasurements from "./RecentMeasurements";
import SearchBox from "./SearchBox";
import SearchButtons from "./SearchButtons";
import SearchResult from "./SearchResult";

export function getBasicParams(parser: ParamsParser) {
    const pageSizeStr: string = parser.getString(
        "pageSize",
        pagingDefault.pageSize.toString(),
    );
    const pageSize: number | "all" =
        pageSizeStr !== "all" ? parseNumber(pageSizeStr) : "all";

    return {
        page: parser.getInt("page", 1),
        pageSize,
        query: parser.getString("q", ""),
    };
}

export function setBasicParams(
    parser: ParamsParser,
    value: {
        page: number;
        pageSize: number | "all";
        query: string;
    },
) {
    parser.setString("q", value.query);
    parser.setInt("page", value.page, 1);
    parser.setString(
        "pageSize",
        (value.pageSize ?? "").toString(),
        pagingDefault.pageSize.toString(),
    );
}

export function getMinDate(range: ETimeRange) {
    let time = new Date();

    switch (range) {
        default:
        case ETimeRange.Any:
            return "";

        case ETimeRange.PastHour:
            time = subHours(time, 1);
            break;

        case ETimeRange.PastDay:
            time = subDays(time, 1);
            break;

        case ETimeRange.PastWeek:
            time = subWeeks(time, 1);
            break;

        case ETimeRange.PastMonth:
            time = subMonths(time, 1);
            break;

        case ETimeRange.PastYear:
            time = subYears(time, 1);
            break;
    }

    return time.toISOString();
}

interface ISearchProps extends RouteComponentProps {
    intl: IntlShape;
    project: number;

    name: string;
}

interface ISearchState {
    items: ISearchDocumentModel[];
    total: number;

    undeletedIds: string[];
    selection: ISelectedItem[];
    emptyResult: boolean;

    suggestions?: string[];
    tags?: IMatchCategory[];
    types?: IMatchCategory[];
    wavelengths?: IMatchCategory[];
    devices?: IMatchCategory[];
    comments?: IMatchCategory[];
}

class Search extends React.PureComponent<ISearchProps, ISearchState> {
    public readonly state: ISearchState = {
        items: [],

        undeletedIds: [],
        selection: [],

        total: 0,
        emptyResult: false,
    };

    private readonly trigger = debounce(this.refresh, IDLE_DELAY);

    private get params() {
        const parser = new ParamsParser(this.props.location.search);

        return sortObject({
            ...getBasicParams(parser),
            asc: parser.getBoolean("asc", false),
            dateRange: parser.getString(
                "modified",
                ETimeRange.Any,
            ) as ETimeRange,
            sort: parser.getString(
                "sort",
                ESortColumns.Modified,
            ) as ESortColumns,
        });
    }

    private set params(value) {
        const parser = new ParamsParser();
        parser.setString("modified", value.dateRange, ETimeRange.Any);
        parser.setString("sort", value.sort, ESortColumns.Modified);
        parser.setBoolean("asc", value.asc, false);
        setBasicParams(parser, value);

        const {history, location} = this.props;
        history.replace({...location, search: parser.toString()});
    }

    public async componentDidMount() {
        await this.trigger();
    }

    public componentWillUnmount() {
        this.trigger.cancel();
    }

    @boundMethod
    public setQuery(value: ISearchBoxValue) {
        this.params = {...this.params, ...value};
        this.trigger();
    }

    @boundMethod
    public setPage(page: number) {
        this.params = {...this.params, page};
        this.trigger();
    }

    @boundMethod
    public applySort(sort: ESortColumns, asc: boolean) {
        this.params = {...this.params, sort, asc};
        this.trigger();
    }

    @boundMethod
    public setSelection(selection: ISelectedItem[]) {
        this.setState({selection});
    }

    @boundMethod
    public showRecentMeasurements() {
        this.params = {
            ...this.params,
            asc: false,
            query: ESearchQueries.Measurements,
            sort: ESortColumns.Created,
        };
        this.trigger();
    }

    @boundMethod
    public getItemName(id: string) {
        const item = this.state.items.find((x) => x.docId.includes(id));
        return item?.name ?? "";
    }

    @boundMethod
    public setUndeletedIds(undeletedIds: string[]) {
        this.setState({undeletedIds});
    }

    @boundMethod
    public resetUndeletedIds() {
        this.setState({undeletedIds: []});
    }

    public render() {
        return (
            <ContainerCol>
                {this.renderDeleteError()}

                <ContainerActivity
                    left={this.renderLeft()}
                    right={this.renderRight()}
                />
            </ContainerCol>
        );
    }

    private renderDeleteError() {
        const {items, undeletedIds} = this.state;

        if (!undeletedIds.length) {
            return null;
        }

        const names = undeletedIds.map(
            (id) => items.find((x) => x.docId.includes(id))?.name,
        );

        return (
            <ErrorAlert
                header={<T>Insufficient permissions</T>}
                onDismiss={this.resetUndeletedIds}
            >
                {this.renderPlural(names.length)}
                <ul>
                    {names.map((x) => (
                        <li key={x}>{x}</li>
                    ))}
                </ul>
            </ErrorAlert>
        );
    }

    private renderPlural(count: number) {
        const {intl} = this.props;

        switch (count) {
            case 1:
                return intl2Str(
                    intl,
                    "The following file could not be deleted:",
                );

            default:
                return intl2Str(
                    intl,
                    "The following files could not be deleted:",
                );
        }
    }

    private renderLeft() {
        const {project, name} = this.props;
        const {
            comments,
            devices,
            emptyResult,
            selection,
            suggestions,
            tags,
            types,
            wavelengths,
        } = this.state;
        const {query, dateRange, pageSize} = this.params;
        const searchBoxValue: ISearchBoxValue = {dateRange, pageSize, query};

        return (
            <React.Fragment>
                <SearchButtons
                    name={name}
                    project={project}
                    selection={selection}
                    getItemName={this.getItemName}
                    onDeleted={this.trigger}
                    onDelete={this.setUndeletedIds}
                />

                <SearchBox
                    tags={tags}
                    types={types}
                    wavelengths={wavelengths}
                    devices={devices}
                    comments={comments}
                    value={searchBoxValue}
                    suggestions={suggestions}
                    emptyResult={emptyResult}
                    onSearch={this.setQuery}
                />

                {this.renderResult()}
            </React.Fragment>
        );
    }

    private renderRight() {
        const {project} = this.props;
        const {query, sort, asc} = this.params;
        const canShowMore =
            query !== ESearchQueries.Measurements ||
            sort !== ESortColumns.Created ||
            asc;

        return (
            <React.Fragment>
                <RecentMeasurements
                    project={project}
                    canShowMore={canShowMore}
                    onMoreClicked={this.showRecentMeasurements}
                />
                <ProjectAudit project={project} />
            </React.Fragment>
        );
    }

    private renderResult() {
        const {project} = this.props;
        const {emptyResult, items, selection, total} = this.state;
        const {asc, page, pageSize, sort} = this.params;

        if (emptyResult) {
            return (
                <p className="text-center text-warning">
                    <T>Your search did not match any data.</T>
                </p>
            );
        }

        return (
            <React.Fragment>
                <SearchResult
                    project={project}
                    items={items}
                    sortBy={sort}
                    isAscending={asc}
                    selection={selection}
                    applySort={this.applySort}
                    onSelected={this.setSelection}
                />

                <Pager
                    page={page}
                    pageSize={pageSize}
                    total={total}
                    bottomMargin={true}
                    onChange={this.setPage}
                />
            </React.Fragment>
        );
    }

    private async retrieveAll(page: number) {
        const {items} = this.state;
        const pageSize =
            this.params.pageSize !== "all" ? this.params.pageSize : 200;

        const response = await this.getPage(page);
        if (!response?.items || response.page !== page) {
            return;
        }

        this.setState(
            {
                emptyResult: !response.items.length,
                items: items.concat(response.items),

                suggestions: response.suggestions,
                tags: response.tags,
                total: response.total,
                types: response.types,
                wavelengths: response.wavelengths,
                devices: response.devices,
                comments: [{key: "", count: 0}],

                selection: [],
            },
            async () => {
                // retrieve only if there's a next page
                if (response.page * pageSize < response.total) {
                    await this.retrieveAll(page + 1);
                    return;
                }
            },
        );
    }

    private async getPage(page: number) {
        const params = this.params;
        const pageSize =
            this.params.pageSize !== "all" ? this.params.pageSize : 200;

        try {
            const response = await http
                .get(`/api/projects/${this.props.project}/docs`, {
                    searchParams: {
                        asc: params.asc.toString(),
                        minDate: getMinDate(params.dateRange),
                        page,
                        pageSize,
                        query: params.query,
                        sort: params.sort,
                    },
                })
                .json<IProjectSearchResult>();

            return response;
        } catch {
            return undefined;
        }
    }

    @boundMethod
    private async refresh() {
        const params = this.params;

        if (params.pageSize !== "all") {
            const response = await this.getPage(params.page);
            if (!response) {
                return;
            }

            this.params = {...this.params, page: response.page};

            this.setState({
                emptyResult: !response.items.length,
                items: response.items,

                suggestions: response.suggestions,
                tags: response.tags,
                total: response.total,
                types: response.types,
                wavelengths: response.wavelengths,
                devices: response.devices,
                comments: [{key: "", count: 0}],

                selection: [],
            });
            return;
        }

        this.params = {...this.params, page: 1};

        // retrieve all pages
        this.setState({items: [], suggestions: [], selection: []}, () =>
            this.retrieveAll(1),
        );
    }
}

export default injectIntl(Search);
