import {boundMethod} from "autobind-decorator";
import React from "react";
import {
    BrowserRouter,
    Redirect,
    Route,
    RouteComponentProps,
    Switch,
} from "react-router-dom";
import {Titled} from "react-titled";

import session from "@/services/session";

import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import {isRecorder} from "@toolbox/functions/browser-checks";
import createRedirector from "@toolbox/functions/create-redirector";
import Provider from "@translate/Provider";
import T from "@translate/T";
import {IntlContext} from "react-intl";
import Footer from "./Footer";
import SessionCheck from "./SessionCheck";
import Startup from "./Startup";
import Eula from "./components/Eula";
import Home from "./components/Home";
import Login from "./components/Login";
import {sepviewLogo} from "./components/SessionExpiredNotification";
import ThirdParty from "./components/ThirdParty";
import AboutConfigModal from "./components/info-global/AboutConfigModal";
import LoadingIndicator from "./components/info-global/LoadingIndicator";
import NavBar from "./components/navbar/Index";
import Warnings from "./components/warnings/Index";
import BlockWarning from "./components/warnings/hard-drice-space/BlockWarning";

interface IAppState {
    started: boolean;
}

class App extends React.PureComponent<{}, IAppState> {
    public readonly state: IAppState = {started: false};

    @boundMethod
    public onReady() {
        this.setState({started: true});
    }

    public getTitle() {
        return "SEPView 7";
    }

    // <Titled> shall also be there if we are in startup
    public render() {
        return (
            <Titled title={this.getTitle}>
                <BrowserRouter>
                    <div className="App">{this.renderApp()}</div>
                </BrowserRouter>
            </Titled>
        );
    }

    // <Provider> only usefull after successfull startup and loading localstorage
    private renderApp() {
        if (!this.state.started) {
            return <Startup onReady={this.onReady} />;
        }

        const noHeaderFooter = !isRecorder();

        return (
            <Provider>
                {noHeaderFooter && <NavBar />}

                <Warnings />

                <Route render={this.renderContent} />

                <SessionCheck>
                    <AboutConfigModal />
                    <LoadingIndicator />
                </SessionCheck>

                {noHeaderFooter && <Footer />}
            </Provider>
        );
    }

    @boundMethod
    private renderContent() {
        const content = session.hasSession
            ? this.renderAuthenticated()
            : this.renderAnonymous();

        return <BlockWarning>{content}</BlockWarning>;
    }

    private renderAnonymous() {
        return (
            <Switch>
                <Route path="/eula" component={Eula} />
                <Route path="/login" render={this.renderLogin} />
                <Route path="/third-party" component={ThirdParty} />

                <Route component={createRedirector("/login")} />
            </Switch>
        );
    }

    private renderAuthenticated() {
        return (
            <Switch>
                <Route path="/eula" component={Eula} />
                <Route path="/login" render={this.redirectAfterLogin} />
                <Route path="/third-party" component={ThirdParty} />

                <Route component={Home} />
            </Switch>
        );
    }

    @boundMethod
    private renderLogin(props: RouteComponentProps) {
        const onAuthenticated = () =>
            props.history.replace(this.redirect(props));

        return (
            <ContainerCol col={4}>
                <div className="bg-focus rounded p-4">
                    <h1>
                        <IntlContext.Consumer children={sepviewLogo} />
                        <T>SEPView login</T>
                    </h1>
                    <hr />
                    <Login onAuthenticated={onAuthenticated} />
                </div>
            </ContainerCol>
        );
    }

    private redirect(props: RouteComponentProps) {
        return (
            new URLSearchParams(props.location.search).get("redirect") ?? "/"
        );
    }

    @boundMethod
    private redirectAfterLogin(props: RouteComponentProps) {
        const redirect = this.redirect(props);
        return <Redirect to={redirect} push={true} />;
    }
}

export default App;
