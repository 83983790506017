import React from "react";
import {IntlShape} from "react-intl";
import {Link} from "react-router-dom";

import {ESearchQueries} from "../models";

import T, {intl2Str} from "@translate/T";
import {
    renderCreateSopButton,
    renderImportButton,
    renderManageButton,
} from "../search/SearchButtons";

export function getTitle(
    name: string,
    id: number,
    className: string = "d-flex",
) {
    return (
        <Link
            className={className}
            id={"project-link-" + id}
            data-testid={"project-link-" + id}
            to={"/project/" + id}
        >
            <span className="text-dot-overflow">
                {name ? name : <T>Unnamed</T>}
            </span>
        </Link>
    );
}

export function getSops(intl: IntlShape, sops: number, id: number) {
    let text = "";
    switch (sops) {
        case 1:
            text = intl2Str(intl, "1 SOP");
            break;

        default:
            text = intl2Str(intl, "{sops} SOPs", {sops});
            break;
    }

    return (
        <Link
            to={`/project/${id}?q=${encodeURIComponent(ESearchQueries.Sops)}`}
        >
            {text}
        </Link>
    );
}

export function getMeasurements(
    intl: IntlShape,
    measurements: number,
    id: number,
) {
    let text = "";
    switch (measurements) {
        case 1:
            text = intl2Str(intl, "1 Measurement");
            break;

        default:
            text = intl2Str(intl, "{measurements} Measurements", {
                measurements,
            });
            break;
    }

    return (
        <Link
            to={`/project/${id}?q=${encodeURIComponent(
                ESearchQueries.Measurements,
            )}`}
        >
            {text}
        </Link>
    );
}

export function getAnalyses(intl: IntlShape, analyses: number, id: number) {
    let text = "";
    switch (analyses) {
        case 1:
            text = intl2Str(intl, "1 Analysis");
            break;

        default:
            text = intl2Str(intl, "{analyses} Analyses", {analyses});
            break;
    }

    return (
        <Link
            to={`/project/${id}?q=${encodeURIComponent(
                ESearchQueries.Analyses,
            )}`}
        >
            {text}
        </Link>
    );
}

export function getButtons(id: number, intl: IntlShape) {
    const _manage = renderManageButton(intl, id, " btn-sm", false);
    const _sop = renderCreateSopButton(id, "btn-sm", true);
    const _import = renderImportButton(intl, id, " btn-sm", false);

    if (!_manage && !_sop && !_import) {
        return null;
    }

    return (
        <React.Fragment>
            {_import}
            {_sop}
            {_manage}
        </React.Fragment>
    );
}
