import React from "react";
import {Link} from "react-router-dom";

import {IProjectModel} from "../models";

import http from "@/services/http";
import Card from "@toolbox/design/Card";
import T from "@translate/T";

interface IRecentProjectsState {
    projects: IProjectModel[];
}

class RecentProjects extends React.PureComponent<{}, IRecentProjectsState> {
    public readonly state: IRecentProjectsState = {projects: []};

    public componentDidMount() {
        this.retrive();
    }

    public render() {
        if (!this.state.projects.length) {
            return null;
        }

        return (
            <Card headerClassName="bg-secondary" title={<T>Recent projects</T>}>
                <table className="table table-sm table-striped table-borderless table-center">
                    <tbody>{this.renderProjects()}</tbody>
                </table>
            </Card>
        );
    }

    private renderProjects() {
        return this.state.projects.map((x, i) => (
            <tr key={i}>
                <td className={x.isHidden ? "bg-tertiary" : undefined}>
                    <Link to={"/project/" + x.id}>{x.name}</Link>
                </td>
            </tr>
        ));
    }

    private async retrive() {
        try {
            const projects = await http
                .get("/api/recent-projects", {cache: "no-cache"})
                .json<IProjectModel[]>();

            this.setState({projects});
        } catch {
            this.setState({projects: []});
        }
    }
}

export default RecentProjects;
