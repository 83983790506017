import {faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {Link, Redirect} from "react-router-dom";

import http from "@/services/http";
import {ERoles} from "@/services/models";
import session from "@/services/session";
import {IProjectModelWithRoles, IRoleAssignment} from "./models";

import PrintReportSettings from "@shared/print-report/PrintReportSettings";
import SaveButton from "@toolbox/button-like/SaveButton";
import ValidatedForm from "@toolbox/button-like/ValidatedForm";
import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import RolesInfo from "@toolbox/modals/RolesInfo";
import Loading from "@toolbox/render-page/Loading";
import NotFound from "@toolbox/render-page/NotFound";
import T from "@translate/T";
import {renderName} from "./CreateProject";
import RolesEditor from "./RolesEditor";

interface IManageProjectProps {
    id: number;
}

interface IManageProjectState {
    name: string;
    isHidden: boolean;

    users: IRoleAssignment[];
    groups: IRoleAssignment[];

    isNotFound: boolean;
    retrieved: boolean;
    showRolesInfo: boolean;

    isSaved: boolean;
}

class ManageProject extends React.PureComponent<
    IManageProjectProps,
    IManageProjectState
> {
    public readonly state: IManageProjectState = {
        name: "",
        isHidden: false,

        groups: [],
        users: [],

        isNotFound: false,
        isSaved: false,
        retrieved: false,
        showRolesInfo: false,
    };

    private readonly saveButton = React.createRef<SaveButton>();

    public async componentDidMount() {
        await this.retrieveProject();
    }

    @boundMethod
    public setName(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.setState({name: e.target.value});
    }

    @boundMethod
    public toggleHidden(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({isHidden: e.target.checked});
    }

    @boundMethod
    public async saveChanges() {
        const {id} = this.props;
        const {name, groups, users, isHidden} = this.state;
        const json: IProjectModelWithRoles = {
            id,
            isHidden,
            name: name.trim(),
            groups,
            users,
        };

        try {
            const response = await http.post("/api/projects/" + id, {json});
            if (!response.ok) {
                throw new http.HTTPError(response);
            }

            if (isHidden) {
                await session.refreshToken();
            }

            this.setState({isSaved: true});

            return true;
        } catch {
            return false;
        }
    }

    @boundMethod
    public setUsers(users: IRoleAssignment[]) {
        this.setState({users});
    }

    @boundMethod
    public setGroups(groups: IRoleAssignment[]) {
        this.setState({groups});
    }

    @boundMethod
    public showRolesInfo(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({showRolesInfo: true});
    }

    @boundMethod
    public hideRolesInfo() {
        this.setState({showRolesInfo: false});
    }

    @boundMethod
    public submitForm() {
        this.saveButton.current?.submitForm();
    }

    public render() {
        const {id} = this.props;
        const {groups, isHidden, isNotFound, isSaved, name, retrieved, users} =
            this.state;
        if (!retrieved) {
            return <Loading />;
        }

        if (isNotFound) {
            return <NotFound id={id} item={<T>The specified project</T>} />;
        }

        if (isSaved) {
            return <Redirect to={"/project/" + id} push={true} />;
        }

        return (
            <ContainerCol col={8}>
                <h3>
                    <T>Edit project</T>
                </h3>
                <hr />

                <ValidatedForm
                    suffixId="manage-project"
                    onSubmit={this.submitForm}
                >
                    <div className="form-row">
                        <div className="col-md-6">
                            {renderName(
                                name,
                                isHidden,
                                this.setName,
                                this.toggleHidden,
                                id,
                            )}
                        </div>
                        <div className="col-md-6">
                            <PrintReportSettings project={id} />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6 mb-2">
                            <Link to="" onClick={this.showRolesInfo}>
                                <T>Group roles</T>
                            </Link>

                            <RolesEditor
                                endpoint="groups"
                                values={groups}
                                onChange={this.setGroups}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <Link to="" onClick={this.showRolesInfo}>
                                <T>User roles</T>
                            </Link>

                            <RolesEditor
                                endpoint="users"
                                values={users}
                                onChange={this.setUsers}
                            />
                        </div>
                    </div>

                    <div className="d-flex">
                        {this.renderDeleteButton()}

                        <SaveButton
                            ref={this.saveButton}
                            id="project-save-button"
                            noAnimation={true}
                            onSave={this.saveChanges}
                        />

                        <Link className="ml-1" to={"/project/" + id}>
                            <button type="button" className="btn btn-secondary">
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    fixedWidth={true}
                                    className="mr-1"
                                />
                                <T>Cancel</T>
                            </button>
                        </Link>
                    </div>

                    {this.renderRolesInfo()}
                </ValidatedForm>
            </ContainerCol>
        );
    }

    private renderDeleteButton() {
        const {id} = this.props;

        return (
            <Link
                id="project-delete-button"
                className="mr-auto"
                to={`/project/${id}/delete`}
            >
                <button
                    type="button"
                    className="btn btn-danger"
                    disabled={!session.hasRole(ERoles.Manager, id)}
                >
                    <FontAwesomeIcon
                        icon={faTrash}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Delete</T>
                </button>
            </Link>
        );
    }

    private renderRolesInfo() {
        if (!this.state.showRolesInfo) {
            return null;
        }

        return <RolesInfo afterClose={this.hideRolesInfo} />;
    }

    private async retrieveProject() {
        try {
            const {groups, name, users, isHidden} = await http
                .get(`/api/projects/${this.props.id}/roles`)
                .json<IProjectModelWithRoles>();

            this.setState({retrieved: true, groups, name, users, isHidden});
        } catch {
            this.setState({isNotFound: true, retrieved: true});
        }
    }
}

export default ManageProject;
