import {IntlShape} from "react-intl";

import {ICsvData, ICsvRow} from "./models";

import {download} from "@/services/download";
import {intl2Num} from "@translate/T";

function createLine(row: ICsvRow, seperator: string) {
    return row
        .map((x) => (!x.length ? "" : '"' + x.replace(/"/g, "'") + '"'))
        .join(seperator);
}

export function getSeperator(intl: IntlShape) {
    return intl2Num(intl, 1.1, 1).includes(",") ? ";" : ",";
}

/**
 * Downloads CSV data as file download.
 * @param name filename, excluding .csv extension.
 * @param data CSV data to download.
 */
export function downloadCsv(intl: IntlShape, name: string, data: ICsvData) {
    const seperator = getSeperator(intl);
    const content = data.map((x) => createLine(x, seperator)).join("\r\n");

    download(`${name}.csv`, content, "text/csv");
}
