import "./SearchItem.module.scss";

import {faMinusSquare, faPlusSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import parseISO from "date-fns/parseISO";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";
import {Link} from "react-router-dom";

import {ECheckStates} from "@toolbox/button-like/models";
import {
    IDocIdInfo,
    IItemSelectionState,
    ISearchDocumentModel,
    ISearchItem,
} from "./models";

import TristateCheckbox from "@toolbox/button-like/TristateCheckbox";
import Time from "@toolbox/design/Time";
import ModuleIcon from "@toolbox/display-blocks/ModuleIcon";
import ACsvErrorIndicator from "./ACsvErrorIndicator";
import CentrifugeSamples from "./CentrifugeSamples";
import FractureSamples from "./FractureSamples";
import RecordingIndicator from "./RecordingIndicator";
import {isCalibration} from "./SearchResult";
import SpocSamples from "./spoc-samples/Index";

export function isSelected(nestedId: string, selection: IItemSelectionState) {
    if (!selection) {
        return false;
    }

    if (selection === true) {
        return true;
    }

    return selection.indexOf(nestedId) >= 0;
}

export function getSelection(
    items: {id: string; selected: boolean}[],
): IItemSelectionState {
    const selected = items.filter((x) => x.selected);
    switch (selected.length) {
        case 0:
            return null;

        case items.length:
            return true;

        default:
            return selected.map((x) => x.id);
    }
}

export interface ISearchItemProps {
    intl: IntlShape;
    item: ISearchItem;
    project: number;

    selection: IItemSelectionState;
    onSelected(
        id: IDocIdInfo,
        doc: ISearchDocumentModel,
        value: IItemSelectionState,
    ): void;
}

interface ISearchItemState {
    isExpanded: boolean;
}

class SearchItem extends React.PureComponent<
    ISearchItemProps,
    ISearchItemState
> {
    public readonly state: ISearchItemState = {isExpanded: false};

    private get checkState() {
        const {selection} = this.props;
        if (!selection) {
            return ECheckStates.NotChecked;
        }

        if (selection === true) {
            return ECheckStates.Checked;
        }

        return ECheckStates.Indeterminate;
    }

    @boundMethod
    public toggleExpand(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({isExpanded: !this.state.isExpanded});
    }

    @boundMethod
    public onSelfSelected(checked: boolean) {
        const {item, onSelected} = this.props;
        const value: IItemSelectionState = checked ? true : null;
        onSelected(item.id, item.doc, value);
    }

    @boundMethod
    public onNestedSelected(selection: IItemSelectionState) {
        const {item, onSelected} = this.props;
        onSelected(item.id, item.doc, selection);
    }

    public render() {
        const {item, intl} = this.props;
        const {doc, id} = item;

        return (
            <React.Fragment>
                <td className="table-checkbox-padding">
                    <label>
                        <TristateCheckbox
                            suffixId={id.id}
                            checkboxClassNameOverwrite="checkbox-primary"
                            value={this.checkState}
                            onChange={this.onSelfSelected}
                        />
                    </label>
                </td>
                <td styleName="doc-icon">
                    <ModuleIcon icon={id.icon} />
                </td>

                {this.renderExpandButton()}

                <td styleName="doc-name">
                    <Link to={id.url}>
                        {doc.name}
                        {this.renderTimestamp(doc)}
                    </Link>
                    <RecordingIndicator completion={parseISO(doc.completion)} />
                    <ACsvErrorIndicator hasError={doc.aCsvError} />
                    {this.renderNestedItems()}
                </td>
                <td>{id.type(intl)}</td>
                <td>
                    <Time value={parseISO(doc.modified)} />
                </td>
                <td>
                    <Time value={parseISO(doc.created)} />
                </td>
            </React.Fragment>
        );
    }

    private renderExpandButton() {
        const {doc, id} = this.props.item;
        if (!doc.nestedItems?.length || isCalibration(doc)) {
            return <td />;
        }

        const icon = !this.state.isExpanded ? faPlusSquare : faMinusSquare;

        return (
            <td aria-label={"expand-" + id.id} onClick={this.toggleExpand}>
                <FontAwesomeIcon icon={icon} fixedWidth={true} />
            </td>
        );
    }

    private renderNestedItems() {
        const {project, item, selection} = this.props;
        if (!this.state.isExpanded) {
            return null;
        }

        return (
            <ul className="list-unstyled">
                <FractureSamples
                    project={project}
                    selection={selection}
                    items={item.doc.nestedItems}
                    onChange={this.onNestedSelected}
                />
                <CentrifugeSamples
                    project={project}
                    selection={selection}
                    items={item.doc.nestedItems}
                    onChange={this.onNestedSelected}
                />
                <SpocSamples
                    project={project}
                    selection={selection}
                    items={item.doc.nestedItems}
                    onChange={this.onNestedSelected}
                />
            </ul>
        );
    }

    private renderTimestamp(doc: ISearchDocumentModel) {
        if (isCalibration(doc)) {
            return (
                <span className="text-muted ml-1">
                    <Time value={parseISO(doc.created)} />
                </span>
            );
        }
    }
}

export default injectIntl(SearchItem);
